import * as React from 'react';

import homeHeroPhoto from '@images/home-hero.jpg';

const HomeHero = () => {
  return (
    <div className="home-hero">
      <img
        src={homeHeroPhoto}
        alt="Wizualizacja architektoniczna budynku na skale stworzona przez Atakama Studio, architekt Katowice"
      />
    </div>
  );
};

export default HomeHero;
