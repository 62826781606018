import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/nav-footer/navigation';
import Footer from '@components/nav-footer/footer';

import HomeHero from '@components/home/home-hero';
import HomeBio from '@components/home/home-bio';
import HomeGrid from '@components/home/home-grid';
import ContactSection from '@components/common/contact-section';

const IndexPage = ({pageContext}) => {
  const projects = pageContext.projects;

  const projektyRef = React.useRef(null);
  const contactRef = React.useRef(null);

  const scrollHandler = element => {
    switch (element) {
      case 'projekty':
        projektyRef.current.scrollIntoView({block: 'end'});
        break;
      case 'kontakt':
        contactRef.current.scrollIntoView({block: 'end'});
        break;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Atakama Studio – Pracownia architektury w Katowicach</title>
        <meta
          name="description"
          content="Jesteśmy biurem architektonicznym z Katowic, działamy na terenie Śląska. Projekty budynków mieszkalnych, osiedli, domów jednorodzinnych, budynków usługowych i biurowych"
        />
        <meta property="og:title" content={`Atakama Studio – Pracownia architektury`} />
        <meta
          property="og:description"
          content="Atakama Studio to pracownia architektury z Katowic. Projekty budynków mieszkalnych, osiedli, domów jednorodzinnych, budynków usługowych i biurowych."
        />
      </Helmet>

      <Navigation scrollHandler={scrollHandler} />

      <HomeHero />
      <HomeBio />
      {projects && <HomeGrid refProp={projektyRef} projects={projects} />}

      <ContactSection refProp={contactRef} />

      <Footer />
    </Layout>
  );
};

export default IndexPage;
