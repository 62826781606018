import * as React from 'react';
import {Link} from 'gatsby';

import {Container, Row, Col, Ratio} from 'react-bootstrap';

const HomeGrid = props => {
  const projects = props.projects;

  const renderProjectGrid = () => {
    return projects.map(project => {
      return (
        <div className="home-grid__grid-container__item">
          <Link to={`/projekty/${project.slug}`}>
            <Ratio aspectRatio="16x9">
              <>
                {project.images && (
                  <img src={project.images[0].url} alt={`Wizualizacja architektoniczna projektu ${project.title}`} />
                )}
              </>
            </Ratio>
            <h6>{project.title}</h6>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="home-grid" ref={props.refProp}>
      <Container>
        <Row>
          <Col>
            <div className="home-grid__grid-container">{projects && renderProjectGrid()}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeGrid;
