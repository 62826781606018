import * as React from 'react';

import {Container, Row, Col} from 'react-bootstrap';

import atakamaEmoji from '@images/atakama-emoji.png';

const HomeBio = () => {
  return (
    <div className="home-bio">
      <Container>
        <Row>
          <Col xl={8}>
            <h2>Atakama Studio</h2>
            <p>
              Jesteśmy pracownią architektoniczną, która nie boi się wyzwań. Nasza siedziba znajduje się w Katowicach,
              mimo to nie działamy wyłącznie na Śląsku, a na terenie całej Polski.
            </p>
            <p>
              Wierzymy, że dobra architektura współgra z otoczeniem tworząc finezyjny porządek architektoniczny, dlatego
              każdy nasz projekt traktowany jest indywidualnie. Współpracujemy z inwestorem dobierając najlepsze
              rozwiązania spełniające oczekiwania i potrzeby przyszłych użytkowników.
            </p>

            <p>
              Stajemy na wysokości zadania podejmując się tematów związanych z budynkami mieszkalnymi, w tym domami
              jednorodzinnymi, budynkami usługowymi, biurowymi, hotelowymi, magazynowymi oraz innymi. Wykonujemy
              projekty koncepcyjne, budowlane, wykonawcze, sprawdzamy możliwości zabudowy działek oraz tworzymy
              wizualizacje architektoniczne.
            </p>
            <img src={atakamaEmoji} alt="Ikony przedstawiające Atakama Studio – architekt Katowice" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeBio;
